import { postMessageToOmaPosti } from '@postidigital/posti-components'
import { ID } from '../constants'

export enum InboundMessageType {
  TOKENS = 'TOKENS',
  UNMOUNT = 'UNMOUNT',
}

export enum OutboundMessageType {
  READY = 'READY',
}

export interface InboundMessage {
  type: InboundMessageType
  payload: any
  sender: ID.OMAPOSTI | string
  receiver: ID.FEATURE
}

/**
 * Limit the origin where the feature can be loaded from. This should also be configured by the
 * host application CSP but we can also check it in the code for increased safety.
 */
export const ALLOWED_FEATURE_ORIGIN = /https:\/\/(|.*\.)(postinext\.fi|posti\.fi|posticloud\.fi)$/

// Special id to mark a case where a message is sent to multiple open features
const ALL_RECEIVERS_ID = '*'

/**
 * Detect localhost context
 */
export const isLocalhost = () =>
  Boolean(
    window.location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === '[::1]' ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
  )

/**
 * Validate the received message event. The event must be from a proper domain and it should
 * have matching sender and receiver fields. For localhost context the domain validation is skipped.
 */
export const isValidMessage = (event: MessageEvent, feature: string, host: string) => {
  const messageIsValid = event.data?.sender === host && [feature, ALL_RECEIVERS_ID].includes(event.data?.receiver)

  // Allow localhost without explicit domain validation
  if (isLocalhost()) {
    return messageIsValid
  }

  const originIsValid = event.origin === window.location.origin && ALLOWED_FEATURE_ORIGIN.test(event.origin)
  return originIsValid && messageIsValid
}

/**
 * Send message to OmaPosti
 */
export const sendMessage = ({ type, payload }: { type: OutboundMessageType; payload: any }) => {
  postMessageToOmaPosti({ type, payload, sender: ID.FEATURE, receiver: ID.OMAPOSTI }, '*')
}
