export default {
  fatalError: {
    title: 'Yhteys palvelimelle epäonnistui.',
    body: 'Lähettämisen palvelua ei voitu ladata.',
    reload: 'Yritä uudelleen',
  },
  emptyView: {
    title: 'Shared feature',
  },
  keyManagement: {
    title: 'API-avaimet',
    subTitle: 'Muokkaa ja hallinnoi API avaimia',
    createNew: 'Luo uusi',
  },
}
