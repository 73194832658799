import React from 'react'
import styled from 'styled-components'
import { Button } from '@postidigital/posti-components'
import { mediaQuery } from '../../utils/mediaQuery'

const StyledActionsContainer = styled.div`
  display: flex;
  margin-left: 1rem;
`
export const ListActionButton = styled(Button)`
  white-space: nowrap;
`

const ListActions: React.FC = ({ children }) => {
  return <StyledActionsContainer>{children}</StyledActionsContainer>
}

export default ListActions
