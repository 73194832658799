import React, { useContext, useState, useEffect } from 'react'
import { unmountComponentAtNode } from 'react-dom'
import { Router } from 'react-router'
import { Switch, Route } from 'react-router-dom'
import type { MountOptions } from '@postidigital/posti-feature-loader'
import { createBrowserHistory } from 'history'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { I18nextProvider } from 'react-i18next'
import { Button, Loading } from '@postidigital/posti-components'

import { ThemeWrapper } from '../../utils/ThemeWrapper'
import { StyledErrorBody, StyledErrorTitle, StyledFatalError, StyledWrapper, StyledContainer } from './root.style'
import { ID } from '../../constants'
import { StoreContext } from '../../store'
import { initLocales } from '../../locales'
import {
  InboundMessage,
  InboundMessageType,
  OutboundMessageType,
  i18nInstance,
  sendMessage,
  isValidMessage,
} from '../../utils'
import { XyzTheme } from '@postidigital/posti-theme'
import { KeyManagement } from '../keyManagement'

initLocales()

type RootProps = { containerId: string } & MountOptions
export const RootView: React.FC<RootProps> = observer(({ history = createBrowserHistory() }) => {
  const [t] = useTranslation()
  const { appStore, authStore } = useContext(StoreContext)
  const [error, setError] = useState(false)

  const retry = () => {
    setError(false)
  }

  const onMessage = (event: MessageEvent) => {
    if (!isValidMessage(event, ID.FEATURE, ID.OMAPOSTI)) {
      return
    }

    const data: InboundMessage = event.data

    switch (data.type) {
      case InboundMessageType.TOKENS:
        const tokens = data.payload
        if (tokens.encoded) {
          authStore.setIdToken(tokens.encoded.idToken)
          authStore.setRoleToken(tokens.encoded.roleToken)
          appStore.setLoading(false)
        }
        break
      case InboundMessageType.UNMOUNT:
        unmountComponentAtNode(document.getElementById(ID.FEATURE))
        break
    }
  }

  useEffect(() => {
    window.addEventListener('message', onMessage)
    sendMessage({ type: OutboundMessageType.READY, payload: null })

    return () => {
      window.removeEventListener('message', onMessage)
    }
  }, [error])

  return (
    <ThemeWrapper>
      <I18nextProvider i18n={i18nInstance}>
        <StyledWrapper>
          <StyledContainer>
            {appStore.loading && (
              <div>
                <Loading color={XyzTheme.color.brandCardboard} />
              </div>
            )}
            {!appStore.loading && error && (
              <StyledFatalError>
                <StyledErrorTitle as="h1" size="md">
                  {t('fatalError.title')}
                </StyledErrorTitle>
                <StyledErrorBody size="md">{t('fatalError.body')}</StyledErrorBody>
                <Button size="sm" onClick={retry} onKeyPress={retry}>
                  {t('fatalError.reload')}
                </Button>
              </StyledFatalError>
            )}
            {!appStore.loading && !error && (
              <Router history={history}>
                <Switch>
                  <Route path="" component={KeyManagement} />
                </Switch>
              </Router>
            )}
          </StyledContainer>
        </StyledWrapper>
      </I18nextProvider>
    </ThemeWrapper>
  )
})
