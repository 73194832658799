import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'
import { GraphQLClient as graphqlRequest } from 'graphql-request'
import { Variables as GraphQLVariables } from 'graphql-request/dist/src/types'
import { authStore } from '../store'

interface QueryResult<T> {
  request: Promise<T>
  controller: AbortController
}

export class GraphQLClient {
  constructor(private endpoint: string = null) {
    if (!this.endpoint) {
      throw new Error('Please declare the API endpoint on the GraphQLClient constructor')
    }
  }

  public request<T>(query: string, variables?: GraphQLVariables, headers?: object): QueryResult<T> {
    if (!query || query === '') {
      throw new Error('Please pass a query.')
    }
    const controller = new AbortController()
    const signal = controller.signal
    const authorization = authStore.idToken
      ? {
          Authorization: `Bearer ${authStore.idToken}`,
        }
      : {}
    const role = authStore.roleToken ? { 'X-OmaPosti-Roles': authStore.roleToken } : {}

    const requestHeaders = {
      ...authorization,
      ...headers,
      ...role,
      'Content-Type': 'application/json;charset=UTF-8',
    }

    const graphQLClient = new graphqlRequest(this.endpoint, {
      headers: requestHeaders,
      mode: 'cors',
      signal,
    } as any)

    return {
      request: graphQLClient.request<T>(query, variables),
      controller,
    }
  }
}
