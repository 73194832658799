import styled, { css } from 'styled-components'

import { Title, Text, Loading, Headline } from '@postidigital/posti-components'
import { mediaQuery } from '../../utils'

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;

  ${mediaQuery.lg`
    flex-direction: row;
  `}
`
export const StyledTitle = styled(Headline)`
  color: ${({ theme }) => theme.xyz.color.neutralWhite};
`

export const StyledContainer = styled.div`
  position: relative;
  flex-grow: 1;

  & > * > section {
    margin-bottom: 2.5rem;
  }
`

export const StyledFatalError = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  margin-top: 6rem;

  svg {
    margin-bottom: ${({ theme }) => theme.spacing.md}rem;
  }
`

export const StyledErrorTitle = styled(Title)`
  margin-bottom: ${({ theme }) => theme.spacing.xs}rem;
`

export const StyledErrorBody = styled(Text)`
  margin-bottom: ${({ theme }) => theme.spacing.md}rem;
`

export const StyledLoading = styled(Loading)`
  margin-left: auto;
  margin-right: auto;
  display: inherit;
`
