import React from 'react'
import { AppStore } from './app.store'
import { AuthStore } from './auth.store'

export const appStore = new AppStore()
export const authStore = new AuthStore()

export const StoreContext = React.createContext({
  appStore,
  authStore,
})
