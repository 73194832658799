export interface ApiKey {
  Id?: number
  Name: string
  Value: string
  Enabled: boolean
}

export enum fieldName {
  Name = 'Name',
  Value = 'Value',
  Enabled = 'Enabled',
}
