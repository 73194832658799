import { action, observable } from 'mobx'
import { persist } from 'mobx-persist'

export class AuthStore {
  @persist
  @observable
  idToken: string | null

  @persist
  @observable
  roleToken: string | null

  @action
  setIdToken = (idToken: string): void => {
    this.idToken = idToken
  }

  @action
  setRoleToken = (roleToken: string): void => {
    this.roleToken = roleToken
  }

  @action
  reset = (): void => {
    this.idToken = null
    this.roleToken = null
  }
}
