import { action, observable, computed } from 'mobx'
import moment from 'moment'
import i18next, { TFunction, Resource } from 'i18next'

export const SHORT_DATE = 'D.M.'
export const LONG_DATE = 'D.M.YYYY'

export enum Locale {
  FI = 'fi',
  EN = 'en',
  SV = 'sv',
}

export const defaultLocale = Locale.FI

const MOMENT_START_OF_YEAR = moment().startOf('year').startOf('day')

const TODAY_IS_NEAR_YEAR_START = moment().startOf('day').isBefore(MOMENT_START_OF_YEAR.add(1, 'month'))

const MOMENT_WEEK_AGO = moment().subtract(1, 'week').startOf('day')

export const i18nInstance = i18next.createInstance()

export class I18n {
  constructor() {
    this.changeLocale = this.changeLocale.bind(this)
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.changeLocale, false)
  }

  @observable
  locale: Locale = defaultLocale

  @computed
  get getLocale() {
    return this.locale
  }

  @action
  changeLocale(e) {
    this.locale = e.data.locale

    i18nInstance.changeLanguage(this.locale)

    moment.locale(this.locale)
    moment.updateLocale(this.locale, this.createRelativeDateConfig(t))
  }

  private createRelativeDateConfig = (t: TFunction) => {
    const defaultResolver = function () {
      // If the date is from this week, return day of week (Mon, Tue, ...)
      if (this.isSameOrAfter(MOMENT_WEEK_AGO)) {
        return 'ddd'
      }
      // If the date is from last year or before, return long date, only if the date is not near the start of a year
      if (this.isSameOrBefore(MOMENT_START_OF_YEAR) && !TODAY_IS_NEAR_YEAR_START) {
        return 'D.M.YYYY'
      }
      // Else, return the short date format
      return 'D.M.'
    }

    return {
      calendar: {
        sameDay: `[${t('formatters.today')}]`,
        lastDay: `[${t('formatters.yesterday')}]`,
        nextDay: defaultResolver,
        lastWeek: defaultResolver,
        nextWeek: defaultResolver,
        sameElse: defaultResolver,
      },
    }
  }

  @action
  addResourceBundle = (locale: Locale, ns: string, resource: Resource) => {
    if (locale && ns && resource) {
      i18nInstance.addResourceBundle(locale, ns, resource)
    }
  }

  @action
  init(translations: Resource, ns: string) {
    const htmlLocale = typeof document !== 'undefined' ? document.documentElement.lang.toLowerCase() : defaultLocale

    Object.keys(Locale).forEach((l) => {
      if (Locale[l] === htmlLocale) {
        this.locale = htmlLocale as Locale

        return
      }
    })

    window.addEventListener('message', this.changeLocale, false)

    i18nInstance.init({
      resources: translations,
      lng: this.locale,
      whitelist: Object.values(Locale),
      fallbackLng: Locale.FI,
      ns: [ns],
      defaultNS: ns,
      debug: false,
      interpolation: {
        escapeValue: false,
      },
      react: {
        wait: true,
      },
    })

    moment.locale(Locale.FI)
    moment.updateLocale(Locale.FI, this.createRelativeDateConfig(t))
  }
}

export const t = i18nInstance.t.bind(i18nInstance)
export default new I18n()
