import { Body, Input, Loading, Switch, Notification } from '@postidigital/posti-components'
import React, { FormEvent, useState } from 'react'
import Header from '../../components/Header'
import { useTranslation } from 'react-i18next'
import { StyledTitle } from '../root/root.style'
import ListActions from '../../components/ListActions'
import { ListActionButton } from '../../components/ListActions/ListActions'
import { ApiKey, fieldName } from '../../types'
import { Field, Form } from 'react-final-form'
import { FormApi } from 'final-form'
import Table from '../../components/Table'

export const KeyManagement: React.FC = () => {
  const [t] = useTranslation()
  const [showNotification, setShowNotification] = useState(true)
  const [apiKeys, setApiKeys] = useState<ApiKey[]>([
    { Id: 1, Name: 'personal apikey', Value: '458dhuguqe92jdg92r9hgj2rgj9', Enabled: false },
    { Id: 2, Name: 'apikey for app', Value: 'gfhwf09hwf8hogfkwoifjg82gj2g', Enabled: true },
  ])

  const initialData: ApiKey = {
    Name: '',
    Value: '',
    Enabled: true,
  }

  // for testing purposes only to simulate loading
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

  const createNewKey = async (values: ApiKey, form: FormApi) => {
    await sleep(2000)
    setApiKeys([...apiKeys, { Id: apiKeys.length + 1, ...values }])
    resetFormValues(values, form)
    setShowNotification(true)
  }

  const resetFormValues = (values: ApiKey, form: FormApi) => {
    Object.keys(values).forEach((key) => {
      form.change(key, initialData[key])
      form.resetFieldState(key)
    })
  }

  const required = (value) => (value ? undefined : 'Required')

  return (
    <>
      <Header>
        <StyledTitle size="Three" as="h1">
          {t('keyManagement.title')}
        </StyledTitle>
        <Body size="Two"> {t('keyManagement.subTitle')}</Body>
      </Header>
      <div>
        <Table
          data={apiKeys}
          rowsPerPageText={'Rows per page'}
          pageComparisonText={'of'}
          pageText={'Page'}
          goPreviousPageAriaLabel={'Go to previous page'}
          goNextPageAriaLabel={'Go to previous page'}
          withRowSelection={false}
        ></Table>
      </div>

      <Form
        onSubmit={createNewKey}
        initialValues={initialData}
        render={({ handleSubmit, submitting, submitSucceeded, submitFailed, submitError }) => (
          <div>
            <form onSubmit={handleSubmit}>
              <Field name={fieldName.Enabled} type="checkbox">
                {(props) => <Switch id={fieldName.Enabled} label={fieldName.Enabled} {...props.input} />}
              </Field>
              <Field name={fieldName.Name} validate={required}>
                {(props) => (
                  <Input
                    id={fieldName.Name}
                    label={fieldName.Name + ' *'}
                    isInvalid={!props.meta.valid && props.meta.touched}
                    lightBackground
                    message={!!(!props.meta.valid && props.meta.touched) ? 'Name is not valid' : ''}
                    {...props.input}
                  />
                )}
              </Field>
              <Field name={fieldName.Value} validate={required}>
                {(props) => (
                  <Input
                    id={fieldName.Value}
                    label={fieldName.Value + ' *'}
                    isInvalid={!props.meta.valid && props.meta.touched}
                    lightBackground
                    message={!!(!props.meta.valid && props.meta.touched) ? 'Value is not valid' : ''}
                    {...props.input}
                  />
                )}
              </Field>
              <ListActions>
                <ListActionButton disabled={submitting} type="submit">
                  {t('keyManagement.createNew')}
                </ListActionButton>
              </ListActions>
            </form>
            <div>
              {submitting && <Loading preset="brandPink" size="md" />}
              {submitSucceeded && showNotification && (
                <Notification closeText="Close" type="success" width="464px" onClose={() => setShowNotification(false)}>
                  Save is successful.
                </Notification>
              )}
              {submitFailed && !!submitError && showNotification && (
                <Notification closeText="Close" type="alert" width="464px" onClose={() => setShowNotification(false)}>
                  {submitError}
                </Notification>
              )}
            </div>
          </div>
        )}
      />
    </>
  )
}
