import { action, observable } from 'mobx'
import { GraphQLClient } from '../utils'

/**
 * API endpoints for OmaPosti graphql
 */
const API_ENDPOINT = {
  DEV: 'https://dev.oma.postinext.fi',
  TST: 'https://test.oma.postinext.fi',
  STG: 'https://staging.oma.postinext.fi',
  PRD: 'https://oma.posti.fi',
}

export enum ErrorCodes {
  FATAL_ERROR,
}

export class AppStore {
  @observable
  loading: boolean = true

  @observable
  graphql = null

  @observable
  error: ErrorCodes | null = null

  @action
  initGraphQL = () => {
    this.graphql = new GraphQLClient(`${API_ENDPOINT.DEV}/graphql/v2/`)
  }

  @action
  setLoading = (value: boolean) => {
    this.loading = value
  }
}
