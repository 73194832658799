import { DataTable } from '@postidigital/posti-components'
import { DataTableProps } from '@postidigital/posti-components/build/molecules/DataTable/DataTable'
import React, { useMemo } from 'react'
import { fieldName } from '../../types'
import { CheckmarkIcon, CloseIcon } from '@postidigital/posti-components'
const Table = (props: Omit<DataTableProps, 'columns'>) => {
  /* t('contactList.headers.address') as string */
  const columns = useMemo(
    () => [
      {
        Header: fieldName.Name,
        accessor: fieldName.Name,
      },
      {
        Header: fieldName.Value,
        accessor: fieldName.Value,
      },
      {
        Header: fieldName.Enabled,
        accessor: fieldName.Enabled,
        Cell: (data) =>
          data.value ? (
            <span>
              <CheckmarkIcon />
            </span>
          ) : (
            <CloseIcon />
          ),
      },
    ],
    []
  )

  const dataTableData = useMemo(() => props.data, [props.data])

  return <DataTable {...props} columns={columns} data={dataTableData} />
}

export default Table
