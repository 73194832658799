import React from 'react'
import { render, unmountComponentAtNode } from 'react-dom'
import { RootView } from './views/root'
import { ID } from './constants'
import type { MountFn, UnmountFn } from '@postidigital/posti-feature-loader'

export const mount: MountFn = (containerId, options) => {
  render(<RootView containerId={containerId} {...options} />, document.getElementById(containerId))
}

export const unmount: UnmountFn = (containerId) => {
  unmountComponentAtNode(document.getElementById(containerId))
}

Object.assign(window, {
  [ID.FEATURE]: {
    mount,
    unmount,
  },
})
