import React from 'react'
import styled, { css } from 'styled-components'

import { mediaQuery } from '../../utils/mediaQuery'

const StyledHeader = styled.div(
  ({
    theme: {
      xyz: { spacing, color },
    },
  }) => css`
    background-color: ${color.neutralNetworkGray};
    padding-top: ${spacing.space9}rem;
    padding-left: ${spacing.space4}rem;
    padding-right: ${spacing.space4}rem;
    padding-bottom: ${spacing.space5}rem;
    position: relative;
    z-index: 1;
    margin-bottom: ${spacing.space9}rem;

    p {
      margin-top: ${spacing.space5}rem;
      color: ${color.neutralWhite};
    }

    ${mediaQuery.lg`
      padding-left: ${spacing.space13}rem;
      padding-right: ${spacing.space13}rem;
    `}
  `
)

const Header: React.FC = ({ children, ...rest }) => <StyledHeader {...rest}>{children}</StyledHeader>

export default Header
