export enum Routes {
  EMPTY = '/:locale/posti-web-feature-starter',
}

/**
 * Global identifiers
 */
export enum ID {
  OMAPOSTI = 'omaposti',
  // Change this to the feature id of your feature. Also edit the id in public/index.html to match this
  FEATURE = 'key-management',
}
